<template>
  <div>
    <b-col cols="6" md="12" class="py-2">
      <b-card>
        <b-form @submit.prevent="createUser">
          <h1>Gegevens</h1>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="School/vestiging" label-for="school" label-class="mb-1">
                <v-select id="school" v-model="school" :options="schools" :class="$v.school.$error ? 'is-invalid' : ''"
                  :getOptionLabel="(option) => option.name" :clearable="false" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Firstname -->
            <b-col cols="6" md="12">
              <b-form-group label="Voornaam" label-for="firstname">
                <b-form-input id="firstname" :class="$v.user.firstname.$error ? 'is-invalid' : ''"
                  v-model="user.firstname" />
              </b-form-group>
            </b-col>
            <b-col cols="6" md="12">
              <b-form-group label="Naam" label-for="lastname">
                <b-form-input id="lastname" :class="$v.user.lastname.$error ? 'is-invalid' : ''"
                  v-model="user.lastname" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Avatar URL -->
            <b-col cols="12" md="12">
              <b-form-group label="Avatar (optioneel)" label-for="Avatar">
                <b-form-input id="Avatar" v-model="user.avatarUrl" />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <!-- Field: Function -->
            <b-col cols="12" md="12">
              <b-form-group label="Functie" label-for="function">
                <b-form-input id="function" :class="$v.user.function.$error ? 'is-invalid' : ''"
                  v-model="user.function" />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <!-- Field: Phone -->
            <b-col cols="12" md="12">
              <b-form-group label="Telefoon (optioneel)" label-for="phone">
                <b-form-input id="phone" :class="$v.user.phone.$error ? 'is-invalid' : ''" v-model="user.phone" />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols="12" md="12">
              <b-button variant="primary" class="mr-1" size="sm" @click="copySignature">
                <!-- <feather-icon icon="PlusIcon"/> -->
                <span class="text-nowrap">Kopiëren</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form></b-card>
    </b-col>
    <b-col style="padding-left: 40px">
      <br>
      <table id="signature" v-if="school" cellpadding="0" cellspacing="0" border="0" class="sh-src"
        style="margin: 0px; border-collapse: collapse;">
        <tr>
          <td style="padding: 0px 1px 0px 0px;">
            <table cellpadding="0" cellspacing="0" border="0" style="border-collapse: separate; margin: 0px;">
              <tr>
                <td valign="top" style="padding: 0px 30px 0px 1px; vertical-align: top;">
                  <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                    <tr>
                      <td>
                        <img v-if="user.avatarUrl" :src="user.avatarUrl" alt=""
                          :title="user.firstname + ' ' + user.lastname" width="100" height="100"
                          style="display: block; border: 0px; max-width: 100px;" />
                      </td>
                      <td>
                        <div
                          style="font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; font-weight: 700; color: rgb(0, 0, 0); white-space: nowrap; margin: 1px;">
                          {{ user.firstname }} {{ user.lastname }}</div>
                        <div
                          style="font-family: Arial, sans-serif; font-size: 13px; line-height: 18px; white-space: nowrap; color: rgb(136, 136, 136); margin: 1px;">
                          {{ user.function }}</div>
                        <!---->
                        <div
                          style="font-family: Arial, sans-serif; font-size: 13px; line-height: 18px; white-space: nowrap; color: rgb(136, 136, 136); margin: 1px;">
                          {{ school.name }}</div>
                      </td>

                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>

        </tr>
        <tr>
          <td colspan="4" :style="user.avatarUrl ? 'padding: 0px 1px 0px 0px; vertical-align: top;' : 'padding: 10px 1px 0px 0px; vertical-align: top;'">
            <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
              <tr>
                <td style="padding: 0px 1px 0px 0px; vertical-align: top">
                  <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                    <tr v-if="user.phone">
                      <td valign="middle" style="vertical-align: middle; padding: 1px 5px 1px 0px;">
                        <div style="margin: 1px; max-width: 20px;">
                          <img :src="school.icons.phone" alt="" width="20" height="20"
                            style="display: block; border: 0px; max-width: 20px;">
                        </div>
                      </td>
                      <td
                        :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + ' !important; padding: 1px 0px; vertical-align: middle;'">
                        <div style="margin: 1px;">
                          <a :href="'tel:' + user.phone" target="_blank"
                            :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; text-decoration: none !important;'">
                            <span
                              :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; text-decoration: none !important;'">{{
                                user.phone
                              }}</span>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle" style="vertical-align: middle; padding: 1px 5px 1px 0px;">
                        <div style="margin: 1px; max-width: 20px;">
                          <img :src="school.icons.phone" alt="" width="20" height="20"
                            style="display: block; border: 0px; max-width: 20px;">
                        </div>
                      </td>
                      <td
                        :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + ' !important; padding: 1px 0px; vertical-align: middle;'">
                        <div style="margin: 1px;">
                          <a :href="'tel:' + school.phone" target="_blank"
                            :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; text-decoration: none !important;'">
                            <span
                              :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; text-decoration: none !important;'">{{
                                school.phone
                              }}</span>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle" style="vertical-align: middle; padding: 1px 5px 1px 0px;">
                        <div style="margin: 1px; max-width: 20px;">
                          <img :src="school.icons.address" alt="" width="20" height="20"
                            style="display: block; border: 0px; max-width: 20px;">
                        </div>
                      </td>
                      <td
                        style="font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: rgb(136,136,135) !important; padding: 1px 0px; vertical-align: middle;">
                        <div style="margin: 1px;">
                          <span
                            style="font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: rgb(136, 136, 136); text-decoration: none !important;">{{
                              school.address }}</span>
                              <br/>
                              <span
                            style="font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: rgb(136, 136, 136); text-decoration: none !important;">{{
                              school.address2 }}</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
                <td colspan="4" style="padding: 0px 1px 0px 0px; vertical-align: top;">
                  <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                    <tr>
                      <td style="padding: 0px 1px 0px 0px;">
                        <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                          <tr>
                            <td valign="middle" style="padding: 1px 5px 1px 0px; vertical-align: middle;">
                              <div style="margin: 1px; max-width: 20px">
                                <img :src="school.icons.website" alt="" width="20" height="20"
                                  style="display: block; border: 0px; max-width: 20px;">
                              </div>
                            </td>
                            <td
                              :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + ' !important; font-weight: 700; padding: 1px 0px; vertical-align: middle;'">
                              <div style="margin: 1px;">
                                <a :href="school.website" target="_blank"
                                  :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; font-weight: 700; text-decoration: none !important;'">
                                  <span
                                    :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; font-weight: 700; text-decoration: none !important;'">{{
                                      school.website }}</span>
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr :key="f.url" v-for="f in school.facebook">
                            <td valign="middle"
                              style="padding: 1px 5px 1px 0px; vertical-align: middle; max-width: 20px;">
                              <div style="margin: 1px;">
                                <img :src="school.icons.facebook" alt="" width="20" height="20"
                                  style="display: block; border: 0px; max-width: 20px;" />
                              </div>
                            </td>

                            <td
                              :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + ' !important; font-weight: 700; padding: 1px 0px; vertical-align: middle;'">
                              <div style="margin: 1px;">
                                <a :href="f.url" target="_blank"
                                  :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; font-weight: 700; text-decoration: none !important;'">
                                  <span
                                    :style="'font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap; color: ' + school.color + '; font-weight: 700; text-decoration: none !important;'">{{
                                      f.name }}</span>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="5" style="padding: 10px 1px 0px 0px;">
                  <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse;">
                    <tr>
                      <td style="padding: 0px 1px 0px 0px;">
                        <div style="margin: 1px;">
                          <a href="https://vbslebbeke.be" target="_blank">
                            <img :src="school.bannerUrl" alt="" title="Logo" width="400"
                              style="display: block; border: 0px; max-width: 100%">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>

              </tr>
            </table>
          </td>
        </tr>
        <!---->
        <tr>
          <td style="padding: 0px 1px 0px 0px;">
            <table cellpadding="0" cellspacing="0" border="0"
              style="max-width: 600px; margin: 0px; border-collapse: collapse;">
              <tr>
                <td
                  style="padding: 10px 1px 0px 0px; font-family: Arial, sans-serif; font-size: 10px; line-height: 13px; color: rgb(136,136,136);">
                  <div
                    style="font-family: Arial, sans-serif; font-size: 10px; line-height: 13px; color: rgb(136, 136, 136); margin: 1px;">
                    &Oacute;scar Romeroscholen vzw | Kerkstraat 60 | 9200 Dendermonde | T 052 25 88 79
                    <br>Ondernemingsnummer: 0415819204 RPR: Ondernemingsrechtbank Gent afdeling Dendermonde
                    <br>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!---->
        <!---->
      </table>
    </b-col>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    vSelect
  },
  name: 'App',
  data() {
    return {

      user: {
        firstname: '',
        lastname: '',
        function: '',
        phone: '',
        avatarUrl: '',
      },
      schools: [
        {
          value: 'vbs-lebbeke-dorp',
          name: 'VBS Lebbeke Dorp',
          address: "Brusselsesteenweg 43",
          address2: "9280 Lebbeke",
          phone: '052 41 14 18',
          website: 'http://www.vbslebbeke.be/',
          bannerUrl: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/email_handtekening_dorp-w1200.png',
          facebook: [{
            name: 'VBS Lebbeke Dorp',
            url: 'https://www.facebook.com/VBS-Lebbeke-Dorp-210027196131302'
          }
          ],
          icons: {
            facebook: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/facebook.png',
            phone: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/phone.png',
            address: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/address.png',
            email: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/email.png',
            website: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/website.png'
          },
          color: '#164194'
        },
        {
          value: 'vbs-lebbeke-heizijde',
          name: 'VBS Lebbeke Heizijde',
          address: 'Heizijdestraat 7',
          address2: "9280 Lebbeke",
          phone: '052 41 14 18',
          website: 'https://www.vbslebbeke.be/',
          bannerUrl: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/email_handtekening_heizijde-w1200.png',
          facebook: [{
            name: 'VBS Lebbeke Heizijde',
            url: 'https://www.facebook.com/VBS-Heizijde-168066386542496'
          }
          ],
          icons: {
            facebook: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/facebook.png',
            phone: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/phone.png',
            address: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/address.png',
            email: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/email.png',
            website: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/website.png'
          },
          color: '#164194'
        },
        {
          value: 'vbs-lebbeke-samen',
          name: 'VBS Lebbeke',
          address: 'Brusselsesteenweg 43',
          address2: "9280 Lebbeke",
          phone: '052 41 14 18',
          website: 'https://www.vbslebbeke.be/',
          bannerUrl: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/email_handtekening_samen-w1200.png',
          facebook: [{
            name: 'VBS Lebbeke Dorp',
            url: 'https://www.facebook.com/VBS-Lebbeke-Dorp-210027196131302'
          },
          {
            name: 'VBS Heizijde',
            url: 'https://www.facebook.com/VBS-Heizijde-168066386542496'
          }
          ],
          icons: {
            facebook: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/facebook.png',
            phone: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/phone.png',
            address: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/address.png',
            email: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/email.png',
            website: 'https://www.vbslebbeke.be/uploads/lld/emailhandtekening/website.png'
          },
          color: '#164194'
        },
        {
          value: 'debellewij',
          name: 'De Bellewij',
          address: 'Kappelenstraat 43',
          address2: "9280 Denderbelle",
          phone: '052 41 09 64',
          website: 'https://www.dorpsschoolbelle.be/',
          bannerUrl: 'https://www.dorpsschoolbelle.be/uploads/blw/E-mailhandtekening/emailhandtekening_denderbelle.png',
          facebook: [{
            name: 'De Bellewij',
            url: 'https://www.facebook.com/VB-De-Bellewij-526906060827985'
          },

          ],
          icons: {
            facebook: 'https://www.dorpsschoolbelle.be/uploads/blw/E-mailhandtekening/facebook.png',
            phone: 'https://www.dorpsschoolbelle.be/uploads/blw/E-mailhandtekening/phone.png',
            address: 'https://www.dorpsschoolbelle.be/uploads/blw/E-mailhandtekening/address.png',
            email: 'https://www.dorpsschoolbelle.be/uploads/blw/E-mailhandtekening/email.png',
            website: 'https://www.dorpsschoolbelle.be/uploads/blw/E-mailhandtekening/website.png'
          },
          color: '#3f86c7'
        },
      ],
      school: null
    }
  },
  mounted() {
    if (window.location.origin.includes('dorpsschoolbelle.be')) {
      this.school = this.schools[3];
    } else if (window.location.origin.includes('vbslebbeke.be')) {
      this.school = this.schools[2];
    } else {
      this.school = this.schools[2];
    }
  },
  methods: {

    copySignature() {
      var r = document.createRange();
      r.selectNode(document.getElementById('signature'));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(r);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    }
  },
  validations: {
    school: {
      required,
    },
    user: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      function: {
        required
      },

      email: {

        required,
        email,

      },
      phone: {
        minLength: minLength(9),

      },
      profilePic: {

      }

    },
  },
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

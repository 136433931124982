import Vue from 'vue'
import App from './App.vue'
import VueCompositionAPI from '@vue/composition-api'

Vue.config.productionTip = false

// Vuelidate - form validation
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// import core styles
require('@/@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


// Composition API
Vue.use(VueCompositionAPI)

new Vue({
  render: h => h(App),
}).$mount('#app')
